.animated-bg {
  background: linear-gradient(-50deg, #340073, #7725dc);
  background-size: 200% 100%;
  animation: bg-animate 5s ease-in-out infinite;
}

@keyframes bg-animate {
  0% {
    background-position: 100% 0;
  }
  50% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}
