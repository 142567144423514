.fade-in {
  animation: 300ms fadeIn;
  animation-fill-mode: forwards;

  visibility: hidden;
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}
