@keyframes bounce {
  70% {
    transform: translateY(0%);
  }
  80% {
    transform: translateY(-15%);
  }
  90% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(-7%);
  }
  100% {
    transform: translateY(0);
  }
}

.bounce {
  animation-delay: 2s;
  animation-duration: 5s;
  animation-name: bounce;
  animation-timing-function: ease;
  animation-iteration-count: 2;
}
