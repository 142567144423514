:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono", "Roboto Mono",
    "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro", "Fira Mono", "Droid Sans Mono",
    "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;

  --primary-brand-color: #7350ff;
  --secondary-font-color: #afafaf;
  --link-color: white;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;

    --primary-glow: radial-gradient(rgba(1, 65, 255, 0.4), rgba(1, 65, 255, 0));
    --secondary-glow: linear-gradient(
      to bottom right,
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0),
      rgba(1, 65, 255, 0.3)
    );

    --tile-start-rgb: 2, 13, 46;
    --tile-end-rgb: 2, 5, 19;
    --tile-border: conic-gradient(
      #ffffff80,
      #ffffff40,
      #ffffff30,
      #ffffff20,
      #ffffff10,
      #ffffff10,
      #ffffff80
    );

    --callout-rgb: 20, 20, 20;
    --callout-border-rgb: 108, 108, 108;
    --card-rgb: 100, 100, 100;
    --card-border-rgb: 200, 200, 200;
  }
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body,
#root,
#__next,
main {
  height: 100%;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(var(--foreground-rgb));
  /* background: #7350ff; */
  /* overflow: hidden; */
}

/* Hiding scrollbar on desktop */
div::-webkit-scrollbar {
  display: none;
  @media (max-width: 768px) {
    display: unset;
  }
}

/* Hiding scrollbar on desktop */
div {
  scrollbar-width: none;
  @media (max-width: 768px) {
    scrollbar-width: unset;
  }
}

h1,
h2,
h3,
h4,
h5,
p {
  /* font-family: sans-serif; */
  color: black;
}

a {
  color: var(--link-color);
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-color: var(--link-color);
}

h1 {
  font-size: 30px;
  font-weight: 600;
  line-height: 175%;
}

h2 {
  font-size: 30px;
  font-weight: 600;
  line-height: 150%;
}

h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
}

h4 {
  font-size: 16px;
  font-weight: 500;
  color: var(--secondary-font-color);
}

p {
  font-size: 16px;
  line-height: 1.6em;
  margin: 10px 0px;
}

blockquote {
  border-left: solid 3px var(--primary-brand-color);
  background: #f2f2f230;
  padding: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}

blockquote > p {
  font-size: 16px;
  font-weight: 500;
  color: #5c5c5c;
}

ul {
  font-family: var(--font-inter);
}

li {
  line-height: 1.6em;
  font-family: var(--font-inter);
}

ol > li::marker,
ul > li::marker {
  font-weight: 600;
  color: black;
}

code {
  background: #e2e2e2;
  padding: 3px 5px;
  font-weight: 500;
  font-size: 14px;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

@media (max-width: 768px) {
  .drawer {
    background-image: var(--active-gradient);
    padding-bottom: 15px;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 140;
    overflow: scroll;
    display: block;
    height: 100vh;
  }
}

.mobile-only {
  display: none;
}

@media (max-width: 768px) {
  .mobile-only {
    display: flex;
  }
}

.draggable-box {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  border-radius: 70px;
  background: black;
}

.draggable-switch {
  width: 60px;
  height: 60px;
  background: white;
  border-radius: 50%;
  position: relative;
}

.pseudo-partial-border {
  position: relative;
  border: none;
}

.pseudo-partial-border::before,
.pseudo-partial-border::after {
  position: absolute;
  width: 50px;
  height: 50px;
  content: "";
}

/* .pseudo-partial-border::before {
  left: 0;
  top: 0;
  border-left: 3px solid black;
  border-top: 3px solid black;
} */

.pseudo-partial-border::after {
  right: 0px;
  bottom: 0px;
  border-right: 3px solid #5875d3;
  border-bottom: 3px solid #5875d3;
  border-bottom-right-radius: 10px;
}

span {
  font-family: var(--font-inter);
}

.name-link {
  font-weight: 600;
  font-size: 18px;
  font-family: var(--font-inter);
  color: #3360f2;
}

.inline-link {
  color: #3360f2;
  text-decoration: none;
  background: #ebefff;
  border-radius: 50%;
  width: 16px;
  display: inline-flex;
  height: 16px;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  margin: 0px;
  bottom: 2px;
  position: relative;
}

.inline-link:hover {
  background: #b5b5af;
}

.tippy-box[data-theme~="astronaut"] {
  background-color: white;
  box-shadow: rgba(149, 157, 165, 0.3) 0px 8px 24px;
  border: 1px solid white;
  border-radius: 16px;
}

.tippy-box[data-theme~="astronaut"][data-placement^="top"] > .tippy-arrow::before {
  border-top-color: white;
  border: 1px solid white;
  color: white;
}

.tippy-box[data-theme~="astronaut"][data-placement^="bottom"] > .tippy-arrow::before {
  border-bottom-color: white;
  border: 1px solid white;
  color: white;
}

.tippy-box[data-theme~="astronaut"][data-placement^="left"] > .tippy-arrow::before {
  border-left-color: white;
  border: 1px solid white;
  color: white;
}

.tippy-box[data-theme~="astronaut"][data-placement^="right"] > .tippy-arrow::before {
  border-right-color: white;
  border: 1px solid white;
  color: white;
}

.step-list {
  list-style: none;
}

.step-list > div:first-of-type:after {
  top: 50%;
}

.step-list > div:last-of-type:after {
  top: -50%;
}

.calendar {
  border: 2px solid #767671 !important;
  border-radius: 6px;
}

.datetimepicker {
  border: 1px solid #f3f3ee !important;
  border-radius: 3px;
  background: white;
  padding: 2px 5px;
  cursor: pointer;
}

.reportTitleBlock {
  justify-content: space-between;
  width: 100%;
}

@media (max-width: 768px) {
  .engagement-legend {
    display: none;
  }
}
