.pulse-bar {
  height: 1.5px;
  background-color: #b5b5af;
  border-radius: 20px;
  overflow: hidden;

  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% {
    transform: scaleX(1);
    opacity: 1;
  }
  50% {
    transform: scaleX(0.1);
    opacity: 0.1;
  }
  100% {
    transform: scaleX(1);
    opacity: 1;
  }
}
