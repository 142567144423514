.public-DraftEditorPlaceholder-inner {
  color: #767671;
}

/* https://www.draft-js-plugins.com/plugin/mention */
.mentionSuggestionsPopup {
  border-radius: 12px;
  border: 1px solid #000;
  background: #fff;
  box-shadow: 6px 6px 0px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  padding: 8px;
  flex-direction: column;
  /* align-items: flex-start; */
  gap: 4px;
  margin-bottom: 10px;
  margin-left: -11px;
  width: 450px;
  z-index: 1100;
}

.mentionSuggestionsPopupVisible {
  color: black;
}

.mentionSuggestionsEntryFocused {
  border-radius: 4px;
  background: rgba(51, 96, 242, 0.25);
  width: 100%;
}

.mentionSuggestionsEntry {
  width: 100%;
}
