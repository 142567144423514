@keyframes wave {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(0);
  }
}

/* Apply the animation to each image component */
.image1 {
  animation: wave 1.5s ease-in-out 1s infinite;
}

.image2 {
  animation: wave 1.5s ease-in-out 1.2s infinite;
}

.image3 {
  animation: wave 1.5s ease-in-out 1.4s infinite;
}

.image4 {
  animation: wave 1.5s ease-in-out 1.6s infinite;
}

.image5 {
  animation: wave 1.5s ease-in-out 1.8s infinite;
}

.image6 {
  animation: wave 1.5s ease-in-out 2s infinite;
}

.image7 {
  animation: wave 1.5s ease-in-out 2.2s infinite;
}

.image8 {
  animation: wave 1.5s ease-in-out 2.4s infinite;
}

.image9 {
  animation: wave 1.5s ease-in-out 2.6s infinite;
}

.image10 {
  animation: wave 1.5s ease-in-out 2.8s infinite;
}
